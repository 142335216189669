// grey
// -----------------------------------------
@each $key, $color in $greys {
  .bg-grey-#{$key} {
    background-color: $color !important;
  }

  .text-grey-#{$key} {
    color: $color !important;
  }

  .border-grey-#{$key} {
    border-color: $color !important;
  }

  .bg-grey {
    background-color: $grey-background !important;
  }
}

.bg-gradient-grey {
  background: linear-gradient(135deg, #9dd53a 0%, #a1d54f 50%, #80c217 50%, #7cbc0a 100%);
}

// blue
// -----------------------------------------
@each $key, $color in $blues {
  .bg-blue-#{$key} {
    background-color: $color !important;
  }

  .text-blue-#{$key} {
    color: $color !important;
  }

  .border-blue-#{$key} {
    border-color: $color !important;
  }
}

// red
// -----------------------------------------
@each $key, $color in $reds {
  .bg-red-#{$key} {
    background-color: $color !important;
  }

  .text-red-#{$key} {
    color: $color !important;
  }

  .border-red-#{$key} {
    border-color: $color !important;
  }
}

// green
// -----------------------------------------
@each $key, $color in $greens {
  .bg-green-#{$key} {
    background-color: $color !important;
  }

  .text-green-#{$key} {
    color: $color !important;
  }

  .border-green-#{$key} {
    border-color: $color !important;
  }
}

// accent
// -----------------------------------------

.text-body-color {
  color: $body-color !important;
}

.text-success {
  color: $success !important;
}

.text-alert {
  color: $alert !important;
}

// brands
// -----------------------------------------
.text-whatsapp {
  color: $whatsapp !important;
}
