.label {
  @include dt-c-c2;

  align-items: center;
  border-radius: $border-radius;
  display: inline-flex;
  gap: #{itl-rem-calc(0 10)};
  justify-content: center;
  line-height: 1;
  padding: #{itl-rem-calc(9 11.36)};

  &.label--border {
    border-style: solid;
    border-width: 1px;
    padding-bottom: #{itl-rem-calc(8)};
    padding-top: #{itl-rem-calc(8)};
  }

  .icon {
    display: block;
    flex-shrink: 0;
    height: #{itl-rem-calc(16)};
    width: auto;
  }
}

.label-sm {
  @include dt-c-c4;

  padding: #{itl-rem-calc(8 6)};
}
