.text-on-media__media {
  span {
    height: 100% !important;
    padding: 0 !important;
    position: relative !important;
    width: 100% !important;

    > span {
      display: none !important;
    }
  }

  img {
    position: relative !important;
  }
}
