// Colors
// -----------------------------------------
$white: #fff;
$black: #000;

$ci-red-primary: #eb5961;
$ci-blue-secondary: #9cdbd9;
$primary: $ci-red-primary;
$secondary: $ci-blue-secondary;

$reds: (
  10: #ffeeef,
  30: #ffcdce,
  50: #ffabae,
  90: #ff696d,
);

$blues: (
  10: #f5fbfb,
  30: #e1f4f4,
  50: #b5e4e3,
);

$greys: (
  default: #f5f5f5,
  10: #f6f6f6,
  20: #ebebeb,
  30: #d6d6d6,
  40: #c2c2c2,
  50: #adadad,
  60: #999,
  70: #858585,
  80: #707070,
  90: #474747,
  100: #333,
);

$greens: (
  default: #9ddb9c,
  10: #72b671,
);

$grey-background: #f5f5f5;

$body-color: map-get($greys, 100);
$body-background: #fff;

$success: #9ddb9c;
$warning: #fff3cd;
$alert: $ci-red-primary;

$message-success: #69A269;
$message-warning: #f5b85e;
$message-error: #eb5961;

$whatsapp: #25d366;

// Grid / Container
// -----------------------------------------

$container-padding-x: 20px;
$grid-gutter-width: 20px;
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1600px,
);

// Grid / Container
// -----------------------------------------

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: #{itl-rem-calc(6)},
  10: #{itl-rem-calc(10)},
  15: #{itl-rem-calc(15)},
  20: #{itl-rem-calc(20)},
  25: #{itl-rem-calc(25)},
  30: #{itl-rem-calc(30)},
  40: #{itl-rem-calc(40)},
  45: #{itl-rem-calc(45)},
  50: #{itl-rem-calc(50)},
  80: #{itl-rem-calc(80)},
  100: #{itl-rem-calc(100)},
);
$enable-negative-margins: true;

// Typography
// -----------------------------------------
$font-family-base: 'Noto Sans', sans-serif;

// Border
// -----------------------------------------
$border-radius: #{itl-rem-calc(5)};
$border-color: map-get($greys, 30);

// Buttons
// -----------------------------------------
$btn-disabled-opacity: 1;

// Transition
// -----------------------------------------
$default-transition: 0.15;

// Forms
// -----------------------------------------
$form-label-font-size: 0.875em;
$form-label-margin-bottom: 0.2rem;
$form-label-color: map-get($greys, 70);
$input-padding-x: 20px;
$input-padding-y-lg: 12px;
$input-padding-x-lg: 20px;
$input-font-size-lg: 16px;
$input-color: $body-color;
$input-font-weight: 500;
$input-border-color: map-get($greys, 30);
$input-placeholder-color: map-get($greys, 80);
$input-focus-box-shadow: 0;
$input-dark-bg: map-get($greys, 90);
$input-dark-border-color: map-get($greys, 20);
$input-dark-color: map-get($greys, 20);
$input-dark-placeholder-color: map-get($greys, 20);
$form-select-indicator-color: $body-color;
$form-style-grey: map-get($greys, 10);
$form-select-bg-size: 11px 6.3049px;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 82 47'><path fill='#{$form-select-indicator-color}' d='M80.4 9.3 44 45.8c-.4.4-.9.7-1.4.9s-1 .3-1.6.3c-.6 0-1.1-.1-1.6-.3-.5-.2-1-.5-1.4-.9L1.5 9.3C.5 8.3 0 7 0 5.5s.5-2.8 1.6-3.9S4 0 5.4 0s2.7.5 3.8 1.6L41 33.5 72.8 1.6c1-1 2.3-1.5 3.7-1.5 1.5 0 2.8.5 3.8 1.6S82 4.1 82 5.5c0 1.5-.5 2.7-1.6 3.8z'/></svg>");
$form-check-input-border: #{itl-rem-calc(2)} solid $body-color;
$form-check-input-border-radius: #{itl-rem-calc(3)};
$form-check-input-width: 1.125em;
$form-check-padding-start: $form-check-input-width + 0.8em;
$form-check-input-width-xs: 1.572em;
$form-check-input-height-xs: 0.858em;
$form-check-min-height-xs: 0;
$form-switch-padding-start-xs: 2.3em;
$form-check-input-checked-bg-color: $white;
$form-switch-checked-color: $body-color;
$form-check-input-checked-border-color: $body-color;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$body-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2.5' fill='#{$body-color}'/></svg>");
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$body-color}'/></svg>");
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{map-get($greys, 50)}'/></svg>") !default;
$form-check-input-focus-box-shadow: none;
$form-check-input-focus-border: unset;

// Header
// -----------------------------------------
$header-height-dt: #{itl-rem-calc(184)};
$header-height-mobile: #{itl-rem-calc(127)};
$header-bg: #dcdcdf;

// Accordion
// -----------------------------------------
$accordion-border-radius: 0;
$accordion-padding-x: 0;
$accordion-button-focus-box-shadow: none;
$accordion-button-color: map-get($greys, 100);
$accordion-border-color: map-get($greys, 40);
$accordion-border-width: 0;
$accordion-button-active-bg: none;
$accordion-button-active-color: map-get($greys, 100);
$accordion-icon-width: #{itl-rem-calc(11)};
$accordion-icon-height: #{itl-rem-calc(6)};
$accordion-icon-color: map-get($greys, 100);
$accordion-icon-color-light: $white;
$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 82 47' fill='#{$accordion-icon-color}'><path d='M80.4 9.3 44 45.8c-.4.4-.9.7-1.4.9s-1 .3-1.6.3c-.6 0-1.1-.1-1.6-.3-.5-.2-1-.5-1.4-.9L1.5 9.3C.5 8.3 0 7 0 5.5s.5-2.8 1.6-3.9S4 0 5.4 0s2.7.5 3.8 1.6L41 33.5 72.8 1.6c1-1 2.3-1.5 3.7-1.5 1.5 0 2.8.5 3.8 1.6S82 4.1 82 5.5c0 1.5-.5 2.7-1.6 3.8z'/></svg>");
$accordion-button-icon-light: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 82 47' fill='#{$accordion-icon-color-light}'><path d='M80.4 9.3 44 45.8c-.4.4-.9.7-1.4.9s-1 .3-1.6.3c-.6 0-1.1-.1-1.6-.3-.5-.2-1-.5-1.4-.9L1.5 9.3C.5 8.3 0 7 0 5.5s.5-2.8 1.6-3.9S4 0 5.4 0s2.7.5 3.8 1.6L41 33.5 72.8 1.6c1-1 2.3-1.5 3.7-1.5 1.5 0 2.8.5 3.8 1.6S82 4.1 82 5.5c0 1.5-.5 2.7-1.6 3.8z'/></svg>");
$accordion-button-active-icon: $accordion-button-icon;
$accordion-button-active-icon-light: $accordion-button-icon-light;
$accordion-bg: none;

// Breadcrumb
// -----------------------------------------
$breadcrumb-item-padding-x: #{itl-rem-calc(13)};

// Badge
// -----------------------------------------
$badge-font-size: 0.75em;
$badge-color: $white;
$badge-padding-y: 0.35em;
$badge-padding-x: 0.65em;

// Badge
// -----------------------------------------
$modal-content-bg: $white;
$modal-close-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0  27.3 27.3' fill='white'><path d='M2.1,27.3L0,25.2l11.6-11.6L0,2.1L2.1,0l11.6,11.6L25.2,0l2.1,2.1L15.8,13.7l11.6,11.6l-2.1,2.1L13.7,15.8L2.1,27.3z'/></svg>");

// Swiper
// -----------------------------------------
$swiper-scrollbar-trail-height: #{itl-rem-calc(3)};
$swiper-scrollbar-thumb-height: #{itl-rem-calc(5)};
$swiper-scrollbar-margin-top: #{itl-rem-calc(30)};
$swiper-thumb-play-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0  20 20' fill='#{$body-color}'><path d='m7.5 14.5 7-4.5-7-4.5v9zM10 20c-1.4 0-2.7-.3-3.9-.8-1.2-.5-2.3-1.2-3.2-2.1s-1.6-2-2.1-3.2S0 11.4 0 10s.3-2.7.8-3.9S2 3.8 2.9 2.9s2-1.6 3.2-2.1S8.6 0 10 0s2.7.3 3.9.8 2.3 1.2 3.2 2.1 1.6 2 2.1 3.2c.5 1.2.8 2.5.8 3.9s-.3 2.7-.8 3.9c-.5 1.2-1.2 2.3-2.1 3.2s-2 1.6-3.2 2.1c-1.2.5-2.5.8-3.9.8zm0-2c2.2 0 4.1-.8 5.7-2.3S18 12.2 18 10s-.8-4.1-2.3-5.7S12.2 2 10 2s-4.1.8-5.7 2.3S2 7.8 2 10s.8 4.1 2.3 5.7S7.8 18 10 18z'/></svg>");

// Lightbox
// -----------------------------------------
$yarl-color-button: $body-color;
$yarl-color-button-active: $primary;
$yarl-color-button-disabled: map-get($greys, 30);
$yarl-container-background-color: $white;
$yarl-slide-title-color: $body-color;
$yarl-slide-captions-container-padding: #{itl-rem-calc(16)};
$yarl-slide-captions-container-background: rgba(map-get($greys, 20), 0.75);
$yarl-thumbnails-container-background-color: map-get($greys, 10);
$yarl-thumbnails-thumbnail-border-radius: $border-radius;
$yarl-thumbnails-thumbnail-border: map-get($greys, 20);
$yarl-thumbnails-thumbnail-background: $white;
$yarl-thumbnails-container-padding: #{itl-rem-calc(20)};
$yarl-thumbnails-thumbnail-active-border-color: $body-color;
$yarl-thumbnails-thumbnail-icon-color: $body-color;

// Dropdown
// -----------------------------------------
$dropdown-link-color: $body-color;
$dropdown-link-hover-color: $primary;
$dropdown-link-active-color: $primary;
$dropdown-link-active-bg: map-get($greys, 10);
$dropdown-link-hover-bg: map-get($greys, 10);
$dropdown-item-padding-x: #{itl-rem-calc(20)};
$dropdown-item-padding-y: #{itl-rem-calc(10)};
$dropdown-padding-y: 0;

// Ratios
// -----------------------------------------
$aspect-ratios: (
  '1x1': 100%,
  '4x3': calc(3 / 4 * 100%),
  '16x9': calc(9 / 16 * 100%),
  '21x9': calc(9 / 21 * 100%),
);

$table-border-color: map-get($greys, 90);
$table-cell-padding-y: #{itl-rem-calc(6)};
$table-th-font-weight: 500;

// import Bootstrap configuration settings
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/variables-dark';
@import '../../node_modules/bootstrap/scss/maps';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/utilities';

// Add more utilitie classes
// -----------------------------------------
/* stylelint-disable scss/dollar-variable-pattern */
$customUtilities: (
  'min-height-px': (
    responsive: true,
    property: min-height,
    class: min-h-px,
    values: (
      280: #{itl-rem-calc(280)},
    ),
  ),
  'height-px': (
    responsive: true,
    property: height,
    class: h-px,
    values: (
      1: #{itl-rem-calc(1)},
      12: #{itl-rem-calc(12)},
      16: #{itl-rem-calc(16)},
      18: #{itl-rem-calc(18)},
      20: #{itl-rem-calc(20)},
      25: #{itl-rem-calc(25)},
      30: #{itl-rem-calc(30)},
      40: #{itl-rem-calc(40)},
      60: #{itl-rem-calc(60)},
      75: #{itl-rem-calc(75)},
    ),
  ),
  'width-px': (
    responsive: true,
    property: width,
    class: w-px,
    values: (
      40: #{itl-rem-calc(40)},
      75: #{itl-rem-calc(75)},
      100: #{itl-rem-calc(100)},
      150: #{itl-rem-calc(150)},
    ),
  ),
  'object-position': (
    property: object-position,
    values: (
      top: top,
      bottom: bottom,
      left: left,
      right: right,
      center: center,
    ),
  ),
  'position': (
    responsive: true,
    property: position,
    values: static relative absolute fixed sticky,
  ),
  'row-gap': (
    property: row-gap,
    values: (
      20: #{itl-rem-calc(20)},
    ),
  ),
  'color':
    map-merge(
      map-get($utilities, 'color'),
      (
        responsive: true,
      )
    ),
  'text-align': (
    responsive: true,
    property: text-align,
    class: text,
    values: (
      start: left,
      end: right,
      center: center,
      justify: justify,
    ),
  ),
);

$utilities: map-merge($utilities, $customUtilities);
@import '../../node_modules/bootstrap/scss/utilities/api';
