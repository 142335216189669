.icon {
  display: inline-block;
  height: 1em;
  vertical-align: unset;
  width: auto;
}

.icon-2em {
  height: 2em;
}
