.accordion-item {
  border-bottom: #{itl-rem-calc(1)} solid $accordion-border-color;
}

.accordion-button {
  box-shadow: none;
  font-size: inherit;
  font-weight: 700;
  padding: #{itl-rem-calc(9 0)};

  @include media-breakpoint-up(xl) {
    padding: #{itl-rem-calc(21 0 8)};
  }

  &::after {
    height: $accordion-icon-height;
  }
}

.accordion-body {
  padding-top: 0;
}

// accordion variant with no background
.accordion--no-bg {
  background-color: unset;

  .accordion-item,
  .accordion-button {
    background-color: unset;
  }
}

// accordion variant with full width button style
.accordion--btn-style {
  .accordion-button {
    @include dt-c-c1;

    --bs-accordion-inner-border-radius: #{$border-radius};

    background: map-get($greys, 100);
    border-radius: $border-radius;
    color: $white;
    justify-content: center;
    padding: #{itl-rem-calc(11 20 12)};
    text-align: center;
    width: 100%;

    &::after {
      display: none;
    }

    @media (hover: hover) {
      &:hover {
        background: map-get($greys, 90);
      }
    }
  }

  .accordion-item {
    border: 0;
  }
}

// accordion variant with small button style
.accordion--small-btn-style {
  --bs-accordion-btn-icon: #{escape-svg($accordion-button-icon-light)};
  --bs-accordion-btn-active-icon: #{escape-svg($accordion-button-active-icon-light)};
  --bs-accordion-btn-icon-width: #{itl-rem-calc(7)};
  --bs-accordion-btn-icon-height: #{itl-rem-calc(4)};

  .accordion-header {
    display: table;
  }

  .accordion-button {
    @include dt-c-c1;

    --bs-accordion-inner-border-radius: #{$border-radius};

    background: map-get($greys, 100);
    border-radius: $border-radius;
    color: $white;
    font-size: #{itl-rem-calc(14)};
    gap: #{itl-rem-calc(0 12)};
    justify-content: center;
    padding: #{itl-rem-calc(8 13)};

    &::after {
      height: var(--bs-accordion-btn-icon-height);
    }

    @media (hover: hover) {
      &:hover {
        background: map-get($greys, 90);
      }
    }
  }

  .accordion-item {
    border: 0;
  }
}

// accordion alternative style
.accordion--alternative {
  > .accordion-item {
    border-bottom: unset;

    &:first-child {
      > .accordion-header > .accordion-button {
        &:first-child {
          padding-top: 0;
        }
      }
    }

    > .accordion-header > .accordion-button {
      border-bottom: #{itl-rem-calc(1)} solid map-get($greys, 30);

      &[aria-expanded='true'],
      &:hover {
        border-color: map-get($greys, 70);
      }
    }

    > .accordion-collapse > .accordion-body {
      padding: #{itl-rem-calc(18 0 0)};
    }
  }
}
