.products-slider__link {
  align-items: center;
  display: flex;

  .icon {
    display: block;
    flex-shrink: 0;
    height: #{itl-rem-calc(18)};
    margin: #{itl-rem-calc(0 0 0 13)};

    @include media-breakpoint-up(xl) {
      height: #{itl-rem-calc(14)};
    }
  }
}

.products-slider__img {
  padding-bottom: #{itl-rem-calc(419)};

  @include media-breakpoint-up(xl) {
    padding-bottom: 47.8%;
  }
}

.products-slider__teaser {
  border-radius: $border-radius;
  overflow: hidden;
  position: relative;
}

.products-slider__teaser-content {
  color: $white;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  padding: #{itl-rem-calc(20)};
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  @include media-breakpoint-up(md) {
    padding: #{itl-rem-calc(40)};
  }

  @include media-breakpoint-up(lg) {
    max-width: 40%;
  }
}
