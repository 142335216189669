.modal {
  backdrop-filter: blur(#{itl-rem-calc(8)});
  background-color: rgba(0, 0, 0, 0.25);
}

.modal-header {
  padding-right: #{itl-rem-calc(50)};

  .btn-close {
    background: #{escape-svg($modal-close-icon)};
    background-color: $primary;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16px;
    border-radius: 0 0 0 $border-radius;
    height: #{itl-rem-calc(30)};
    margin: 0;
    opacity: 1;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: #{itl-rem-calc(30)};

    @include media-breakpoint-up(sm) {
      border-radius: $border-radius;
      transform: translate(50%, -50%);
    }
  }
}

.modal-media {
  .modal-content {
    --bs-modal-bg: unset;
    --bs-modal-border-width: 0;
  }

  .modal-dialog {
    max-width: calc(100vw - #{itl-rem-calc(80)});
  }

  .modal-body {
    padding: 0;
  }
}

.modal-body {
  video {
    border-radius: $border-radius;
    display: block;
    height: auto;
    max-width: 100%;
    overflow: hidden;
    width: 100%;
  }
}
