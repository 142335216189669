.page-footer {
  color: map-get($greys, 90);
  font-size: #{itl-rem-calc(14)};

  @include media-breakpoint-up(xl) {
    font-size: #{itl-rem-calc(16)};
  }
}

.page-footer__logo {
  @include set-transition(color);

  color: map-get($greys, 90);
  display: block;
  width: #{itl-rem-calc(80)};

  svg, svg path {
    fill: currentcolor;
  }

  &:hover,
  &.active {
    color: $primary;
  }
}

.footer-socials__ul {
  display: flex;
  gap: #{itl-rem-calc(15)};
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-socials__a {
  background: map-get($greys, 90);
  border-radius: 100%;
  display: block;
  height: #{itl-rem-calc(24)};
  padding: #{itl-rem-calc(5)};
  width: #{itl-rem-calc(24)};
  @include set-transition(background);

  &:hover {
    background: $primary;
  }

  .icon {
    display: block;
    fill: $white;
    height: 100%;
    object-fit: contain;
    object-position: center;
    width: 100%;
  }
}

.footer-nav {
  padding: #{itl-rem-calc(22 0 0)};

  @include media-breakpoint-up(xl) {
    display: flex;
    margin: 0 #{itl-rem-calc(-$grid-gutter-width / 2)};
    padding: 0;
  }
}

.footer-nav__item {
  background: none;
  border: 0;
  border-bottom: #{itl-rem-calc(1)} solid map-get($greys, 30);
  border-radius: 0;
  flex-shrink: 0;
  padding: 0;
  width: 100%;

  @include media-breakpoint-up(xl) {
    border: 0;
    padding: #{itl-rem-calc(0 $grid-gutter-width)};
    width: 25%;
  }

  .accordion-collapse {
    @include media-breakpoint-up(xl) {
      display: block !important; // overwrites default acc style
      height: auto !important; // overwrites default acc style
    }
  }
}

.footer-nav__header {
  .accordion-button {
    padding: #{itl-rem-calc(12 0)};

    @include media-breakpoint-up(xl) {
      padding: #{itl-rem-calc(0 0 24)};

      &::after {
        display: none;
      }
    }
  }
}

.footer-nav__body {
  padding: #{itl-rem-calc(0 0 7)};

  @include media-breakpoint-up(xl) {
    padding: 0;
  }
}

.footer-nav__li {
  &:not(:last-child) {
    padding: #{itl-rem-calc(0 0 6)};

    @include media-breakpoint-up(xl) {
      padding: #{itl-rem-calc(0 0 10)};
    }
  }
}

.footer-payments__ul {
  display: flex;
  flex-wrap: wrap;
  gap: #{itl-rem-calc(10)};
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: #{itl-rem-calc(20 0 0)};

  @include media-breakpoint-up(xl) {
    gap: #{itl-rem-calc(20)};
    padding: #{itl-rem-calc(40 0 0)};
  }
}

.footer-payments__li {
  background: $white;
  border-radius: $border-radius;
  filter: grayscale(100%);
  flex-shrink: 0;
  height: #{itl-rem-calc(35)};
  padding: #{itl-rem-calc(7)};

  .icon {
    display: block;
    height: 100%;
    object-fit: contain;
    object-position: center;
    width: 100%;
  }
}

.footer-info-nav__ul {
  display: flex;
  flex-wrap: wrap;
  gap: #{itl-rem-calc(2 20)};
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: #{itl-rem-calc(21 0)};

  @include media-breakpoint-up(xl) {
    padding: #{itl-rem-calc(53 0)};
  }
}

.footer-info-nav__a {
  @include mob-c-c3;
  @include set-transition(color);

  color: map-get($greys, 90);
  display: block;
  text-decoration: none;

  @include media-breakpoint-up(xl) {
    @include dt-c-c3;
  }

  &:hover,
  &.active {
    color: $primary;
  }
}
