.newsletter {
  color: $white;
  padding: #{itl-rem-calc(20 0)};

  @include media-breakpoint-down(xl) {
    position: relative;
    z-index: 0;

    &::before {
      background: map-get($greys, 100);
      content: '';
      display: block;
      height: 100%;
      left: 50%;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      width: 100vw;
      z-index: -1;
    }
  }

  @include media-breakpoint-up(xl) {
    background: map-get($greys, 100);
    border-radius: $border-radius;
    padding: #{itl-rem-calc(40 20)};
  }
}

.newsletter__title {
  margin-bottom: #{itl-rem-calc(9)};

  @include media-breakpoint-up(xl) {
    margin-bottom: #{itl-rem-calc(21)};
  }
}
