// search field dropdown
.rbt-menu {
  --bs-dropdown-item-padding-y: 0;
  --bs-dropdown-link-hover-color: #{$primary};
  --bs-dropdown-link-active-color: #{$primary};
  --bs-dropdown-link-hover-bg: #{$white};
  --bs-dropdown-link-active-bg: #{$white};

  font-size: #{itl-rem-calc(14)};
  max-height: 90vh !important;

  @include media-breakpoint-up(xl) {
    font-size: #{itl-rem-calc(14)};
  }
}

.dropdown-item__search {
  align-items: center;
  display: flex;
  padding: #{itl-rem-calc(10 0)};
  white-space: initial;

  .dropdown-item:not(:last-child) & {
    border-bottom: #{itl-rem-calc(1)} solid map-get($greys, 20);
  }

  img {
    flex-shrink: 0;
    height: auto;
    margin: #{itl-rem-calc(0 10 0 0)};
    width: #{itl-rem-calc(40)};

    @include media-breakpoint-up(xl) {
      margin: #{itl-rem-calc(0 20 0 0)};
      width: #{itl-rem-calc(50)};
    }
  }
}

.dropdown-item__search-wrapper {
  @include media-breakpoint-up(xl) {
    width: calc(100% / 4);
  }

  @media only screen and (width >= 1700px) {
    width: calc(100% / 5);
  }
}

// custom input radio field
.dropdown-item--check {
  display: flex;

  input {
    appearance: none;
    height: auto;
    margin: #{itl-rem-calc(0 10 0 0)};
    width: #{itl-rem-calc(15)};

    &:checked {
      background: {
        image: #{escape-svg(
            url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' fill='#{map-get($greys, 100)}'><path d='M413.505 91.951L133.49 371.966l-98.995-98.995c-4.686-4.686-12.284-4.686-16.971 0L6.211 284.284c-4.686 4.686-4.686 12.284 0 16.971l118.794 118.794c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-11.314-11.314c-4.686-4.686-12.284-4.686-16.97 0z'/></svg>")
          )};
        position: center;
        repeat: no-repeat;
        size: contain;
      }
    }
  }
}
