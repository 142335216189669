.profile-slider {
  .swiper-button-prev,
  .swiper-button-next {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .swiper-pagination {
    margin-top: #{itl-rem-calc(20)};

    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}
