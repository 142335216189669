@use 'sass:math';

.swiper {
  .slider__container--with-scrollbar & {
    padding-bottom: calc(math.div($swiper-scrollbar-thumb-height, 2));
  }
}

.swiper--mobile-full {
  @include media-breakpoint-down(sm) {
    overflow: visible;
  }
}

.swiper--container-window-edge {
  overflow: visible;
}

.swiper-slide {
  display: flex;
  height: auto;
}

// Swiper arrows
.swiper:not(.swiper-thumbs) {
  .swiper-button-prev,
  .swiper-button-next {
    @include set-transition(background-color);

    background: map-get($greys, 10);
    border: #{itl-rem-calc(1)} solid map-get($greys, 30);
    border-radius: 100%;
    cursor: pointer;
    height: #{itl-rem-calc(40)};
    opacity: 0;
    position: absolute;
    top: calc(50% - #{itl-rem-calc(20)});
    visibility: hidden;
    width: #{itl-rem-calc(40)};
    z-index: 2;
    background: {
      position-y: center;
      repeat: no-repeat;
      size: #{itl-rem-calc(11 19)};
    }

    .slider__container--with-scrollbar & {
      transform: translateY(
        calc(
          (
              #{$swiper-scrollbar-margin-top} + #{$swiper-scrollbar-trail-height} + #{(
                  math.div($swiper-scrollbar-thumb-height, 2)
                )}
            ) / -2
        )
      );
    }

    @media (hover: hover) {
      &:hover {
        background-color: map-get($greys, 20);
      }

      .swiper:hover & {
        opacity: 1;
        visibility: visible;
      }
    }

    .slider__container--arrows-always & {
      opacity: 1;
      visibility: visible;
    }

    &.swiper-button-disabled {
      opacity: 0.4 !important;
      pointer-events: none;
    }

    &.swiper-button-lock {
      display: none;
    }
  }

  .swiper-button-prev {
    left: #{itl-rem-calc(10)};
    background: {
      image: #{escape-svg(
          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47 82' fill='#{$accordion-icon-color}'><path d='M37.7 80.4 1.2 44c-.4-.4-.7-.9-.9-1.4-.2-.5-.3-1-.3-1.6 0-.6.1-1.1.3-1.6.2-.5.5-1 .9-1.4L37.7 1.5C38.7.5 40 0 41.5 0s2.8.5 3.9 1.6S47 4 47 5.4s-.5 2.7-1.6 3.8L13.5 41l31.8 31.8c1 1 1.5 2.3 1.5 3.7 0 1.5-.5 2.8-1.6 3.8-1.1 1.1-2.3 1.6-3.8 1.6-1.4.1-2.6-.4-3.7-1.5z'/></svg>")
        )};
      position-x: #{itl-rem-calc(12)};
    }

    .slider__container--arrows-always & {
      left: 0;
    }
  }

  .swiper-button-next {
    right: #{itl-rem-calc(10)};
    background: {
      image: #{escape-svg(
          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47 82' fill='#{$accordion-icon-color}'><path d='M9.3,1.6L45.8,38c0.4,0.4,0.7,0.9,0.9,1.4s0.3,1,0.3,1.6c0,0.6-0.1,1.1-0.3,1.6c-0.2,0.5-0.5,1-0.9,1.4 L9.3,80.5c-1,1-2.3,1.5-3.8,1.5c-1.5,0-2.8-0.5-3.9-1.6C0.5,79.3,0,78,0,76.6c0-1.4,0.5-2.7,1.6-3.8L33.5,41L1.6,9.2 c-1-1-1.5-2.3-1.5-3.7s0.5-2.8,1.6-3.8S4.1,0,5.5,0C7,0,8.2,0.5,9.3,1.6z'/></svg>")
        )};
      position-x: #{itl-rem-calc(14)};
    }

    .slider__container--arrows-always & {
      right: 0;
    }
  }
}

.swiper-scrollbar-horizontal {
  @include scrollbar-horizontal;

  margin-top: #{itl-rem-calc(11)};

  @include media-breakpoint-up(xl) {
    margin-top: $swiper-scrollbar-margin-top;
  }
}

// Swiper Pagination
.swiper-pagination {
  align-items: center;
  display: flex;
  gap: #{itl-rem-calc(0 15)};
  height: #{itl-rem-calc(9)};
  justify-content: center;
  width: 100%;

  .hero-slider & {
    bottom: #{itl-rem-calc(30)};
    left: 0;
    position: absolute;
    z-index: 1;

    @include media-breakpoint-up(xl) {
      bottom: #{itl-rem-calc(66)};
    }
  }

  &.swiper-pagination-lock {
    display: none;
  }
}

@mixin bullet-active {
  background: map-get($greys, 100);
  transform: scale(1.3, 1.6);

  .hero-slider & {
    background: rgba(map-get($greys, 10), 0.5);
    border-color: rgba(map-get($greys, 20), 0.5);
  }
}

.swiper-pagination-bullet {
  @include set-transition((border, transform, background));

  background: map-get($greys, 40);
  border-radius: $border-radius;
  cursor: pointer;
  height: #{itl-rem-calc(5)};
  width: #{itl-rem-calc(30)};

  .hero-slider & {
    background: rgba(map-get($greys, 10), 0.2);
    border: #{itl-rem-calc(1)} solid rgba(map-get($greys, 20), 0);
  }

  &.slider__bullet--active,
  &.swiper-pagination-bullet-active {
    @include bullet-active;
  }

  @media (hover: hover) {
    &:hover {
      @include bullet-active;
    }
  }
}

// single product slider with thumbs
.product-slider {
  display: flex;
}

.product-slider__thumbs {
  flex-shrink: 0;
  height: #{itl-rem-calc(490)};
  margin: #{itl-rem-calc(0 50 0 0)};
  padding: #{itl-rem-calc(30 0 20)};
  position: relative;
  width: #{itl-rem-calc(100)};

  img,
  video {
    @include set-transition(opacity);

    opacity: 0.5;
  }

  .swiper {
    height: #{itl-rem-calc(430)};
  }

  .swiper-slide {
    border: #{itl-rem-calc(1)} solid map-get($greys, 20);
    border-radius: $border-radius;
    cursor: pointer;
    height: #{itl-rem-calc(100)} !important;
    overflow: hidden;
    width: #{itl-rem-calc(100)} !important;
    @include set-transition(border-color);

    @media (hover: hover) {
      &:hover {
        img,
        video {
          opacity: 1;
        }
      }
    }

    &:hover:not(.swiper-slide-thumb-active) {
      border-color: map-get($greys, 50);
    }
  }

  .slider__image {
    width: 100%;

    * {
      display: block;
      height: 100%;
      left: 0;
      object-fit: contain;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .slider__image--video {
    position: relative;

    &::before {
      backdrop-filter: blur(0.25rem);
      border-radius: 100%;
      content: '';
      height: #{itl-rem-calc(40)};
      left: calc(50% - #{itl-rem-calc(20)});
      overflow: hidden;
      position: absolute;
      top: calc(50% - #{itl-rem-calc(20)});
      width: #{itl-rem-calc(40)};
      z-index: 1;
      background: {
        color: rgba(map-get($greys, 50), 0.5);
        image: #{escape-svg($swiper-thumb-play-icon)};
        position: center;
        repeat: no-repeat;
        size: #{itl-rem-calc(40)};
      }
    }
  }

  .swiper-slide-thumb-active {
    border-color: map-get($greys, 90);

    img,
    video {
      opacity: 1;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    @include set-transition(background);

    align-items: center;
    background: map-get($greys, 10);
    cursor: pointer;
    display: flex;
    font-size: #{itl-rem-calc(7)};
    height: #{itl-rem-calc(20)};
    justify-content: center;
    left: 0;
    position: absolute;
    width: 100%;

    @media (hover: hover) {
      &:hover {
        background: map-get($greys, 20);
      }
    }

    &.swiper-button-disabled {
      color: map-get($greys, 40);
      opacity: 0.6;
      pointer-events: none;
    }
  }

  .swiper-button-prev {
    top: 0;
  }

  .swiper-button-next {
    bottom: 0;
  }
}

.product-slider__images {
  width: 100%;

  @include media-breakpoint-up(xl) {
    width: calc(100% - #{itl-rem-calc(150)});
  }
}
