.input-group-absolute {
  position: relative;

  .rbt-aux {
    align-items: center;
    color: $primary;
    display: inline-flex;
    height: 100%;
    opacity: 0.5;
    position: absolute;
    right: #{itl-rem-calc(60)};
    top: 0;
  }
}

.input-group-absolute__item {
  align-items: center;
  color: map-get($greys, 80);
  display: inline-flex;
  inset: 0 0 0 auto;
  justify-content: center;
  padding: #{itl-rem-calc(0 $input-padding-x)};
  position: absolute;
  z-index: 1;

  &:hover {
    color: $primary;
  }

  .icon {
    display: block;
    height: #{itl-rem-calc(20)};
    width: auto;

    .input-group-absolute--password & {
      &.icon-visibility {
        height: #{itl-rem-calc(15)};
      }
    }
  }
}

.form-control {
  line-height: 1;
  padding: #{itl-rem-calc(9 20 10)};
}

.form-control-dark {
  background: $input-dark-bg;
  border-color: $input-dark-border-color;
  color: $input-dark-color;

  &:focus {
    background-color: $input-dark-bg;
    border-color: $input-dark-border-color;
    color: $input-dark-color;
  }

  &::placeholder {
    color: $input-dark-placeholder-color;
    opacity: 1;
  }
}

// form select
.form-select {
  padding: #{itl-rem-calc(9 20 8)};
}

.form-select-lg {
  background-position: right #{itl-rem-calc(10)} center;
  padding: #{itl-rem-calc($form-select-padding-y-lg)} #{itl-rem-calc(27)} #{itl-rem-calc($form-select-padding-y-lg)} #{itl-rem-calc(
      $form-select-padding-x-lg
    )};
}

.form-select--grey,
.form-control--grey {
  background-color: $form-style-grey;
}

.form-steps {
  display: flex;
  justify-content: space-between;
  position: relative;

  &::after {
    background: map-get($greys, 20);
    content: '';
    height: #{itl-rem-calc(2)};
    left: #{itl-rem-calc(20)};
    position: absolute;
    right: #{itl-rem-calc(20)};
    top: #{itl-rem-calc(25)};
  }
}

.form-steps__number {
  align-items: center;
  background: $white;
  border-radius: 100%;
  display: flex;
  font-size: #{itl-rem-calc(20)};
  height: #{itl-rem-calc(50)};
  justify-content: center;
  line-height: 1;
  position: relative;
  width: #{itl-rem-calc(50)};
  z-index: 1;
}

// form switch
.form-check-xs {
  @include dt-p-p4;

  min-height: $form-check-min-height-xs;
  padding-left: $form-switch-padding-start-xs;

  &.form-check-reverse {
    padding-left: 0;
    padding-right: $form-switch-padding-start-xs;
  }
}

.form-check-input {
  .form-check-xs & {
    height: $form-check-input-height-xs;
    margin-left: $form-switch-padding-start-xs * -1;
    margin-right: 0;
    margin-top: #{itl-rem-calc(2)};
    width: $form-check-input-width-xs;
  }

  .form-switch.form-check-reverse & {
    margin-left: 0;
    margin-right: $form-switch-padding-start-xs * -1;
  }

  .form-check-payment & {
    height: 1.0625em;
    width: 1.0625em;
  }
}

.form-check-label {
  .form-check-payment & {
    align-items: center;
    display: flex;

    .payment-icon {
      flex-shrink: 0;
      height: #{itl-rem-calc(19)};
      margin-right: #{itl-rem-calc(12)};
      width: #{itl-rem-calc(36)};
    }

    .icon {
      height: 100%;
      max-width: 100%;
      width: auto;
    }
  }
}
