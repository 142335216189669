.media-text-slider {
  left: calc(50% - 50vw);
  position: relative;
  width: 100vw;

  .swiper-pagination {
    margin: #{itl-rem-calc(20 0 0)};

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  .swiper-slide {
    width: 100%;

    @include media-breakpoint-up(md) {
      max-width: #{itl-rem-calc(1130)};
      width: 78.5%;
    }
  }
}

.media-text-slide__media {
  height: #{itl-rem-calc(500)};

  @include media-breakpoint-up(xl) {
    height: #{itl-rem-calc(650)};
  }

  img,
  video {
    height: 100%;
    inset: 0;
    object-fit: cover;
    position: absolute;
    width: 100%;
  }
}

.media-text-slide__content-inner {
  &.bg-blurry {
    backdrop-filter: blur(#{itl-rem-calc(8)});
    background-color: rgba(map-get($greys, 100), 0.2);
  }
}
