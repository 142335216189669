.brands-slider__slide {
  a {
    &::before {
      content: '';
      display: block;
      padding-bottom: 40%;
      width: 100%;
    }
  }

  img {
    display: block;
    height: 100%;
    left: 0;
    margin: 0;
    object-fit: contain;
    object-position: center;
    padding: 0;
    padding: #{itl-rem-calc(10)};
    position: absolute;
    top: 0;
    width: 100%;

    @include media-breakpoint-up(xl) {
      padding: #{itl-rem-calc(30)};
    }
  }
}
