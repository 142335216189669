.section-spacing {
  margin: {
    bottom: #{itl-rem-calc(40)};
    top: #{itl-rem-calc(40)};
  }

  @include media-breakpoint-up(xl) {
    margin: {
      bottom: #{itl-rem-calc(80)};
      top: #{itl-rem-calc(80)};
    }
  }
}

.section-spacing-padding {
  padding: {
    bottom: #{itl-rem-calc(40)};
    top: #{itl-rem-calc(40)};
  }

  @include media-breakpoint-up(xl) {
    padding: {
      bottom: #{itl-rem-calc(80)};
      top: #{itl-rem-calc(80)};
    }
  }
}

.section-spacing-bottom {
  margin-bottom: #{itl-rem-calc(40)};

  @include media-breakpoint-up(xl) {
    margin-bottom: #{itl-rem-calc(80)};
  }
}

.section-spacing-top {
  margin-top: #{itl-rem-calc(40)};

  @include media-breakpoint-up(xl) {
    margin-top: #{itl-rem-calc(80)};
  }
}

.section-spacing-padding-top {
  padding-top: #{itl-rem-calc(40)};

  @include media-breakpoint-up(xl) {
    padding-top: #{itl-rem-calc(80)};
  }
}

.section-spacing-padding-bottom {
  padding-bottom: #{itl-rem-calc(40)};

  @include media-breakpoint-up(xl) {
    padding-bottom: #{itl-rem-calc(80)};
  }
}

.section-spacing--sm {
  margin: {
    bottom: #{itl-rem-calc(30)};
    top: #{itl-rem-calc(30)};
  }

  @include media-breakpoint-up(xl) {
    margin: {
      bottom: #{itl-rem-calc(60)};
      top: #{itl-rem-calc(60)};
    }
  }
}

.section-spacing-bottom--sm {
  margin-bottom: #{itl-rem-calc(30)};

  @include media-breakpoint-up(xl) {
    margin-bottom: #{itl-rem-calc(60)};
  }
}

.section-spacing-top--sm {
  margin-top: #{itl-rem-calc(30)};

  @include media-breakpoint-up(xl) {
    margin-top: #{itl-rem-calc(60)};
  }
}

.section-spacing-padding--xs {
  padding: {
    bottom: #{itl-rem-calc(30)};
    top: #{itl-rem-calc(30)};
  }
}

.section-spacing-padding-top--xs {
  padding-top: #{itl-rem-calc(30)};
}

.section-spacing-top--xs {
  margin-top: #{itl-rem-calc(30)};
}

.hr,
hr {
  border-color: $border-color;
  opacity: 1;
}

.gap-x-20 {
  column-gap: #{itl-rem-calc(20)};
}

.gap-y-20 {
  row-gap: #{itl-rem-calc(20)};
}

.pad-t-0 {
  padding-top: 0;
}

.pad-t-1 {
  padding-top: 1rem;
}

.pad-t-2 {
  padding-top: 2rem;
}

.pad-t-3 {
  padding-top: 3rem;
}

.pad-t-4 {
  padding-top: 4rem;
}

.pad-t-5 {
  padding-top: 5rem;
}

.pad-b-0 {
  padding-bottom: 0;
}

.pad-b-1 {
  padding-bottom: 1rem;
}

.pad-b-2 {
  padding-bottom: 2rem;
}

.pad-b-3 {
  padding-bottom: 3rem;
}

.pad-b-4 {
  padding-bottom: 4rem;
}

.pad-b-5 {
  padding-bottom: 5rem;
}

