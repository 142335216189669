.category-card {
  @include set-transition((border, color));

  background: $white;
  border: #{itl-rem-calc(1)} solid map-get($greys, 20);
  border-radius: $border-radius;
  overflow: hidden;
  position: relative;
  width: 100%;

  &:hover {
    border-color: $primary;
    color: $primary;
  }
}

.category-card__image {
  display: block;
  position: relative;
  width: 100%;

  &::before {
    content: '';
    display: block;
    padding-bottom: 60%;
  }

  img {
    display: block;
    height: 100%;
    left: 0;
    object-fit: contain;
    padding: #{itl-rem-calc(12)};
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.category-card__link {
  color: inherit;
  display: block;
  font-size: #{itl-rem-calc(16)};
  line-height: 1;
  padding: #{itl-rem-calc(0 0 10)};
  text-align: center;
  text-decoration: none;

  &::before {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
