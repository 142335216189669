.popup-container {
  background: transparent;
  bottom: 0;
  display: none;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
}

.popup {
  background: $white;
  border-radius: #{itl-rem-calc($border-radius)};
  box-shadow: #{itl-rem-calc(1 1 4 0)} rgba($black, .5);
  display: block;
  left: #{itl-rem-calc($container-padding-x)};
  line-height: 0;
  max-height: calc(100vh - #{itl-rem-calc($container-padding-x)} - #{itl-rem-calc($container-padding-x)});
  max-width: calc(100vw - #{itl-rem-calc($container-padding-x)} - #{itl-rem-calc($container-padding-x)});
  pointer-events: initial;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  img {
    border-radius: #{itl-rem-calc($border-radius)};
  }

  @include media-breakpoint-up(sm) {
    bottom: 12rem;
    left: auto;
    max-width: 360px;
    right: #{itl-rem-calc($container-padding-x)};
    top: auto;
    transform: none;
  }
}

.popup-close {
  align-items: center;
  background-color: $white;
  border: 0;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  height: #{itl-rem-calc(18)};
  margin: 0;
  opacity: .5;
  padding: 0;
  position: absolute;
  right: #{itl-rem-calc(8)};
  top: #{itl-rem-calc(8)};
  width: #{itl-rem-calc(18)};

  svg {
    height: #{itl-rem-calc(8)};
    width: #{itl-rem-calc(8)};
  }
}
