button {
  border: none;
}

.btn {
  @include dt-c-c1;

  align-items: center;
  box-shadow: none;
  display: inline-flex;
  gap: #{itl-rem-calc(0 14)};
  justify-content: center;
  padding: #{itl-rem-calc(11 20 12)};
  position: relative;

  &:hover {
    box-shadow: #{itl-rem-calc(0 0 8)} rgba(map-get($greys, 60), 0.12),
      #{itl-rem-calc(0 0 6)} rgba(map-get($greys, 100), 0.12);
  }

  .icon {
    display: block;
    flex-shrink: 0;
    height: 1em;
    width: auto;
  }

  .spinner-border {
    border-width: 0.2em;
    height: 1em;
    width: 1em;
  }

  &.btn-sm {
    @include dt-c-c4;

    gap: #{itl-rem-calc(0 12)};
    padding: #{itl-rem-calc(7 13)};

    &.btn-lg-md {
      @include media-breakpoint-up(lg) {
        @include dt-c-c1;

        gap: #{itl-rem-calc(0 14)};
        padding: #{itl-rem-calc(11 20 12)};
      }
    }

    &.btn-xl-md {
      @include media-breakpoint-up(xl) {
        @include dt-c-c1;

        gap: #{itl-rem-calc(0 14)};
        padding: #{itl-rem-calc(11 20 12)};
      }
    }
  }

  &.btn-xs {
    gap: #{itl-rem-calc(0 7)};
    padding: #{itl-rem-calc(5 7)};
    @include dt-c-c5;

    .icon {
      height: 0.7em;
    }
  }

  &.btn-lg {
    padding: #{itl-rem-calc(14 22 16)};
  }
}

.btn-primary {
  @include button-variant(
    $ci-red-primary,
    $ci-red-primary,
    $color: $white,
    $hover-background: map-get($reds, 90),
    $hover-border: map-get($reds, 90),
    $hover-color: $white,
    $active-background: map-get($reds, 90),
    $active-border: map-get($reds, 90),
    $active-color: $white,
    $disabled-background: map-get($reds, 10),
    $disabled-border: map-get($reds, 10),
    $disabled-color: map-get($reds, 30)
  );
}

.btn-secondary {
  @include button-variant(
    map-get($greys, 100),
    map-get($greys, 100),
    $color: $white,
    $hover-background: map-get($greys, 90),
    $hover-border: map-get($greys, 90),
    $hover-color: $white,
    $active-background: map-get($greys, 90),
    $active-border: map-get($greys, 90),
    $active-color: $white,
    $disabled-background: map-get($greys, 40),
    $disabled-border: map-get($greys, 40),
    $disabled-color: $white
  );
}

.btn-grey {
  @include button-variant(
    map-get($greys, 100),
    map-get($greys, 100),
    $color: $white,
    $hover-background: map-get($greys, 90),
    $hover-border: map-get($greys, 90),
    $hover-color: $white,
    $active-background: map-get($greys, 90),
    $active-border: map-get($greys, 90),
    $active-color: $white,
    $disabled-background: map-get($greys, 40),
    $disabled-border: map-get($greys, 40),
    $disabled-color: $white
  );
}

.btn-light-grey {
  @include button-variant(
    map-get($greys, 10),
    map-get($greys, 10),
    $color: map-get($greys, 100),
    $hover-background: $ci-red-primary,
    $hover-border: $ci-red-primary,
    $hover-color: $white,
    $active-background: $ci-red-primary,
    $active-border: $ci-red-primary,
    $active-color: $white,
    $disabled-background: map-get($greys, 10),
    $disabled-border: map-get($greys, 10),
    $disabled-color: map-get($greys, 20)
  );
}

.btn-outline {
  @include button-variant(
    $white,
    map-get($greys, 90),
    $color: map-get($greys, 90),
    $hover-background: $white,
    $hover-border: map-get($greys, 90),
    $hover-color: map-get($greys, 90),
    $active-background: $white,
    $active-border: map-get($greys, 90),
    $active-color: map-get($greys, 90),
    $disabled-background: $white,
    $disabled-border: map-get($greys, 40),
    $disabled-color: map-get($greys, 40)
  );

  &::before {
    border: #{itl-rem-calc(2)} solid rgba(map-get($greys, 90), 0);
    border-radius: $btn-border-radius;
    content: '';
    display: block;
    inset: #{itl-rem-calc(-1)};
    pointer-events: none;
    position: absolute;
    transition: $btn-transition;
  }

  &:hover {
    &::before {
      border-color: map-get($greys, 90);
    }
  }
}

.btn-link {
  @include button-variant(
    $white,
    $white,
    $color: map-get($greys, 100),
    $hover-background: $white,
    $hover-border: $white,
    $hover-color: $primary,
    $active-background: $white,
    $active-border: $white,
    $active-color: map-get($greys, 100),
    $disabled-background: $white,
    $disabled-border: $white,
    $disabled-color: map-get($greys, 40)
  );

  background: none;
  border-width: 0;
  padding: 0;

  &:hover {
    box-shadow: unset;
  }
}

.btn-img-thumb {
  padding: #{itl-rem-calc(9 20)};

  &.btn-lg {
    padding: #{itl-rem-calc(14 20)};
  }

  .btn-img-thumb__thumb {
    border: #{itl-rem-calc(1)} solid map-get($greys, 20);
    border-radius: 100%;
    object-fit: cover;
    overflow: hidden;
    position: relative;
    width: #{itl-rem-calc(30)};

    &::before {
      content: '';
      display: block;
      padding-bottom: 100%;
      width: 100%;
    }
  }
}

.btn-reset {
  background: none;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  color: $body-color;
  font-size: inherit;
  font-weight: inherit;
  gap: 0;
  line-height: inherit;
  margin: 0;
  padding: 0;

  &::after {
    display: none;
  }

  &:hover {
    background: none;
    box-shadow: none;
  }
}

.btn-close {
  z-index: 1;
}

.btn-outline-grey {
  @include button-variant(
    $white,
    map-get($greys, 30),
    $color: $body-color,
    $hover-background: $white,
    $hover-border: $body-color,
    $hover-color: $body-color,
    $active-background: $body-color,
    $active-border: $body-color,
    $active-color: $white,
    $disabled-background: $white,
    $disabled-border: rgba(map-get($greys, 30), 0.5),
    $disabled-color: rgba($body-color, 0.3)
  );
}
