.teaser-card__text {
  backdrop-filter: blur(#{itl-rem-calc(8)});
  background-color: rgba(map-get($greys, 100), 0.3);
}

.teaser-card__badge {
  @include set-transition(background);
  @media (hover: hover) {
    .teaser-card:hover & {
      background: $primary !important;
    }
  }
}

.teaser-card__image {
  &::before {
    content: '';
    display: block;
    padding-bottom: 100%;

    .teaser-card--large & {
      @include media-breakpoint-only(md) {
        padding-bottom: 50%;
      }
    }

    .page-teaser-slider & {
      padding-bottom: #{itl-rem-calc(315)};
    }

    .page-teaser-slider--height-450 & {
      @include media-breakpoint-up(md) {
        padding-bottom: #{itl-rem-calc(450)};
      }
    }
  }

  img {
    @include set-transition(transform);

    @media (hover: hover) {
      .teaser-card:hover & {
        transform: scale(1.05);
      }
    }
  }
}

.page-teaser-slider .swiper-slide {
  width: #{itl-rem-calc(260)};
}
