.hero-slider {
  left: calc(50% - 50vw);
  position: relative;
  width: 100vw;

  // custom arrow
  .swiper-button-prev,
  .swiper-button-next {
    backdrop-filter: blur(#{itl-rem-calc(4)});
    background-color: rgba(map-get($greys, 50), 0.5);
  }

  .swiper-button-prev {
    background-image: #{escape-svg(
        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47 82' opacity='0.8' fill='#{map-get($greys, 20)}'><path d='M37.7 80.4 1.2 44c-.4-.4-.7-.9-.9-1.4-.2-.5-.3-1-.3-1.6 0-.6.1-1.1.3-1.6.2-.5.5-1 .9-1.4L37.7 1.5C38.7.5 40 0 41.5 0s2.8.5 3.9 1.6S47 4 47 5.4s-.5 2.7-1.6 3.8L13.5 41l31.8 31.8c1 1 1.5 2.3 1.5 3.7 0 1.5-.5 2.8-1.6 3.8-1.1 1.1-2.3 1.6-3.8 1.6-1.4.1-2.6-.4-3.7-1.5z'/></svg>")
      )};
    left: #{itl-rem-calc(20)};
  }

  .swiper-button-next {
    background-image: #{escape-svg(
        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47 82' opacity='0.8' fill='#{map-get($greys, 20)}'><path d='M9.3,1.6L45.8,38c0.4,0.4,0.7,0.9,0.9,1.4s0.3,1,0.3,1.6c0,0.6-0.1,1.1-0.3,1.6c-0.2,0.5-0.5,1-0.9,1.4 L9.3,80.5c-1,1-2.3,1.5-3.8,1.5c-1.5,0-2.8-0.5-3.9-1.6C0.5,79.3,0,78,0,76.6c0-1.4,0.5-2.7,1.6-3.8L33.5,41L1.6,9.2 c-1-1-1.5-2.3-1.5-3.7s0.5-2.8,1.6-3.8S4.1,0,5.5,0C7,0,8.2,0.5,9.3,1.6z'/></svg>")
      )};
    right: #{itl-rem-calc(20)};
  }
}

.hero-slide {
  height: #{itl-rem-calc(405)};
  width: 100%;

  @include media-breakpoint-up(md) {
    height: auto;
    padding-bottom: 39%;
  }

  @include media-breakpoint-up(xl) {
    height: #{itl-rem-calc(562)};
    padding-bottom: 0;
  }
}

.hero-slide__img,
.hero-slide__content {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.hero-slide__img {
  * {
    height: 100% !important;
    left: 0;
    object-fit: cover;
    position: absolute !important;
    top: 0;
    width: 100% !important;
  }
}

.hero-slide__content {
  background: linear-gradient(to bottom, rgba(map-get($greys, 100), 0) 20%, rgba(map-get($greys, 100), 1) 100%);
  padding: #{itl-rem-calc(0 0 60)};

  @include media-breakpoint-up(md) {
    background: linear-gradient(to left, rgba(map-get($greys, 100), 0) 20%, rgba(map-get($greys, 100), 0.66) 100%);
  }

  @include media-breakpoint-up(xl) {
    padding: #{itl-rem-calc(0 0 116)};
  }
}

.hero-slide__btn--cover {
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  a {
    background: none;
    display: block;
    font-size: 0;
    width: 100%;
  }
}
