.default-spinner {
  animation: rotate-spinner 1000ms linear infinite;
  display: block;
  height: auto;
  position: relative;
  width: #{itl-rem-calc(50)};
}

@keyframes rotate-spinner {
  to {
    transform: rotate(360deg);
  }
}
