/* stylelint-disable custom-property-pattern */

:root {
  --yarl__color_button: #{$yarl-color-button};
  --yarl__color_button_active: #{$yarl-color-button-active};
  --yarl__color_button_disabled: #{$yarl-color-button-disabled};
  --yarl__slide_title_color: #{$yarl-slide-title-color};
  --yarl__slide_captions_container_padding: #{$yarl-slide-captions-container-padding};
  --yarl__container_background_color: #{$yarl-container-background-color};
  --yarl__thumbnails_container_background_color: #{$yarl-thumbnails-container-background-color};
  --yarl__thumbnails_thumbnail_border_radius: #{$yarl-thumbnails-thumbnail-border-radius};
  --yarl__thumbnails_thumbnail_background: #{$yarl-thumbnails-thumbnail-background};
  --yarl__thumbnails_container_padding: #{$yarl-thumbnails-container-padding};
  --yarl__thumbnails_thumbnail_border: #{$yarl-thumbnails-thumbnail-border};
  --yarl__thumbnails_thumbnail_active_border_color: #{$yarl-thumbnails-thumbnail-active-border-color};
  --yarl__slide_captions_container_background: #{$yarl-slide-captions-container-background};
  --yarl__thumbnails_thumbnail_icon_color: #{$yarl-thumbnails-thumbnail-icon-color};
}

html {
  background-color: unset;
  scroll-behavior: auto !important;
}

body {
  background-color: unset;
  color: $body-color;
}

.page-wrapper {
  overflow: hidden;
}

.bg-white-full,
.bg-black-full,
.bg-grey-full,
.bg-gradient-grey-full {
  position: relative;

  &::before {
    content: '';
    height: 100%;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 100vw;
    z-index: -1;
  }
}

.bg-white-full {
  &::before {
    background: $white;
  }
}

.bg-grey-full {
  &::before {
    background: $grey-background;
  }
}

.bg-black-full {
  &::before {
    background: $black;
  }
}

.bg-gradient-grey-full {
  &::before {
    background: linear-gradient(
      158deg,
      #{map-get($greys, 10)} 0%,
      #{map-get($greys, 10)} 44%,
      #{map-get($greys, 20)} 44%,
      #{map-get($greys, 20)} 100%
    );
  }
}

::-webkit-scrollbar {
  height: 4px; // no rem
  width: 4px; // no rem

  @include media-breakpoint-up(xl) {
    height: 8px;
    width: 8px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: map-get($greys, 10);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: map-get($greys, 30);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: map-get($greys, 40);
}

.pe-pointer {
  &:hover {
    cursor: pointer;
  }
}

.scroll-margin {
  scroll-margin-top: #{itl-rem-calc(80)};

  @include media-breakpoint-up(xl) {
    scroll-margin-top: #{itl-rem-calc(160)};
  }
}

@mixin width-full {
  left: calc(50% - 50vw);
  position: relative;
  width: 100vw;
}

.width-full {
  @include width-full;
}

.width-md-full {
  @include media-breakpoint-up(md) {
    @include width-full;
  }
}

.width-md-max-full {
  @include media-breakpoint-down(md) {
    @include width-full;
  }
}

@mixin bg-blurry {
  backdrop-filter: blur(#{itl-rem-calc(8)});
  background-color: rgba(map-get($greys, 100), 0.2);
}

.bg-blurry {
  @include bg-blurry;
}

.bg-md-blurry {
  @include media-breakpoint-up(md) {
    @include bg-blurry;
  }
}
