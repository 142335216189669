.offcanvas-navigation {
  --bs-offcanvas-padding-x: #{itl-rem-calc(20)};

  border-radius: #{itl-rem-calc(0 #{$border-radius} #{$border-radius} 0)};

  @include media-breakpoint-up(xl) {
    --bs-offcanvas-padding-x: #{itl-rem-calc(40)};
  }

  .offcanvas-body {
    overflow-y: scroll;
  }

  .accordion-item,
  .accordion-button {
    border: unset;
  }
}

.offcanvas-nav {
  ul {
    background: map-get($greys, 10);
    border-radius: #{itl-rem-calc(0 0 #{$border-radius} #{$border-radius})};
    padding: #{itl-rem-calc(7.5 20)};

    @include media-breakpoint-up(xl) {
      padding: #{itl-rem-calc(15 10)};
    }

    ul {
      border-left: #{itl-rem-calc(1)} solid map-get($greys, 30);
      border-radius: 0;
      margin: #{itl-rem-calc(10 0)};
      padding: #{itl-rem-calc(0 10)};

      @include media-breakpoint-up(xl) {
        margin: #{itl-rem-calc(20 0)};
        padding: #{itl-rem-calc(0 20)};
      }
    }
  }
}

.offcanvas-nav__a,
.offcanvas-nav__button .accordion-button {
  @include set-transition(color);

  border: unset;
  color: $body-color;
  display: flex;
  font-size: #{itl-rem-calc(14)};
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  padding: #{itl-rem-calc(2.5 0)};
  text-align: left;
  text-decoration: none;
  width: 100%;

  @include media-breakpoint-up(xl) {
    padding: #{itl-rem-calc(5 0)};
  }

  @media (hover: hover) {
    &:hover {
      color: $primary;
    }
  }

  &.active {
    color: $primary;
    font-weight: 700;
  }
}

.offcanvas-nav__a-lvl-1,
.offcanvas-nav__button-lvl-1 .accordion-button {
  font-size: #{itl-rem-calc(16)};
  font-weight: 700;
  padding: #{itl-rem-calc(10 0)};

  @include media-breakpoint-up(xl) {
    padding: #{itl-rem-calc(20 0)};
  }

  .offcanvas-nav__li-lvl-1:not(:last-child) & {
    border-bottom: #{itl-rem-calc(1)} solid map-get($greys, 30);
  }
}
