.breadcrumb-wrapper {
  overflow: hidden;
  overflow-x: auto;
  padding: #{itl-rem-calc(20 0)};
}

.breadcrumb {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  white-space: nowrap;

  .breadcrumb-item {
    align-items: center;
    color: map-get($greys, 100);
    display: flex;

    &:last-child {
      color: map-get($greys, 60);
    }

    &:not(:last-child) {
      @include set-transition(color);

      &:hover {
        color: $primary;
      }
    }

    + .breadcrumb-item {
      &::before {
        content: '';
        height: 0.9375em;
        margin-right: $breadcrumb-item-padding-x;
        padding: 0;
        width: 0.5625em;
        background: {
          image: #{escape-svg(
              url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 31 54' fill='#{map-get($greys, 100)}'><path d='M6.1,1.1l24.1,24c0.3,0.3,0.5,0.6,0.6,0.9c0.1,0.3,0.2,0.7,0.2,1.1s-0.1,0.7-0.2,1.1c-0.1,0.3-0.3,0.7-0.6,0.9,L6.1,53c-0.7,0.7-1.5,1-2.5,1s-1.8-0.3-2.6-1.1S0,51.4,0,50.4s0.3-1.8,1.1-2.5l21-20.9L1.1,6.1c-0.7-0.7-1-1.5-1-2.4,s0.3-1.8,1.1-2.5S2.7,0,3.6,0C4.6,0,5.4,0.3,6.1,1.1z'/></svg>")
            )};
          position: center;
          repeat: no-repeat;
          size: contain;
        }
      }
    }
  }

  a {
    color: inherit;
    display: block;
    line-height: 1;
    text-decoration: none;
  }
}
