@mixin scrollbar-horizontal {
  align-items: center;
  background: $white;
  border-radius: $border-radius;
  display: flex;
  height: $swiper-scrollbar-trail-height;
  width: 100%;

  > div {
    background: map-get($greys, 50);
    border-radius: $border-radius;
    height: $swiper-scrollbar-thumb-height;
  }

  &:hover,
  &:focus {
    cursor: grab;
  }
}
