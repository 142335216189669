@mixin list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 0;
  }
}

.list-unstyled {
  @include list-unstyled;
}

@mixin list-styled {
  margin: 0 0 1em;
  padding: 0 0 0 1.6em;

  li {
    padding-bottom: 0.5625em;

    &::marker {
      font-size: 0.9em;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.list-styled {
  @include list-styled;
}

@mixin dl-styled {
  @include dt-p-p3;

  .dl-styled__item {
    border-bottom: #{itl-rem-calc(1)} solid map-get($greys, 20);
    display: flex;
    flex-direction: column;
    hyphens: auto;
    margin: #{itl-rem-calc(0 0 6)};
    padding: #{itl-rem-calc(0 0 6)};

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  dt {
    color: map-get($greys, 90);
    flex-shrink: 0;
    font-weight: 500;
    padding: #{itl-rem-calc(0 0 7)};
    width: 100%;

    @include media-breakpoint-up(sm) {
      padding: #{itl-rem-calc(0 10 0 0)};
      width: 45%;
    }
  }

  dd {
    color: map-get($greys, 80);
    flex-grow: 1;
    font-weight: 500;
    margin: 0;

    * {
      margin-bottom: 0;
    }
  }

  // experimental
  &:not(.dl-styled--show-all) {
    dd {
      [br],
      div {
        opacity: 0;
        pointer-events: none;
        position: absolute;
      }
    }
  }
}

ul,
ol {
  padding-inline-start: 1.2em;
}

.dl-styled {
  @include dl-styled;
}

.list-pro,
.list-contra {
  ul {
    margin: 0;
    padding: 0;
  }

  li,
  p {
    background-position-y: #{itl-rem-calc(4)};
    background-repeat: no-repeat;
    background-size: #{itl-rem-calc(20)};
    list-style: none;
    margin: #{itl-rem-calc(0 0 6 0)};
    padding: #{itl-rem-calc(0 0 0 33)};

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.list-pro {
  li,
  p {
    background-image: #{escape-svg(
        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><circle cx='10' cy='10' r='8' fill='#{map-get($blues, 30)}' /><path fill='#{$body-color}' d='M8.6 11.8 6.4 9.6c-.1-.1-.4-.2-.6-.2s-.6.1-.7.2c-.2.2-.3.4-.3.7 0 .3.1.5.3.7l2.8 2.8c.2.2.4.3.7.3.3 0 .5-.1.7-.3l5.6-5.6c.2-.2.3-.4.3-.7s-.1-.5-.3-.7-.4-.3-.7-.3-.5.1-.7.3l-4.9 5zM10 20c-1.4 0-2.7-.3-3.9-.8S3.8 18 2.9 17.1c-.9-.9-1.6-2-2.1-3.2S0 11.4 0 10s.3-2.7.8-3.9S2 3.8 2.9 2.9s2-1.6 3.2-2.1S8.6 0 10 0s2.7.3 3.9.8 2.3 1.2 3.2 2.1c.9.9 1.6 2 2.1 3.2s.8 2.5.8 3.9-.3 2.7-.8 3.9-1.2 2.3-2.1 3.2c-.9.9-2 1.6-3.2 2.1s-2.5.8-3.9.8zm0-2c2.2 0 4.1-.8 5.7-2.3S18 12.2 18 10s-.8-4.1-2.3-5.7S12.2 2 10 2s-4.1.8-5.7 2.3S2 7.8 2 10s.8 4.1 2.3 5.7S7.8 18 10 18z'/></svg>")
      )};
  }
}

.list-contra {
  li,
  p {
    background-image: #{escape-svg(
        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><circle cx='10' cy='10' r='8' fill='#{map-get($reds, 10)}' /><path fill='#{$body-color}' d='M10 20c-1.3 0-2.7-.3-3.9-.8-1.2-.5-2.3-1.2-3.2-2.1-.9-.9-1.6-2-2.1-3.2C.3 12.7 0 11.3 0 10c0-1.4.3-2.7.8-3.9.5-1.2 1.2-2.3 2.1-3.2.9-.9 2-1.6 3.2-2.1C7.3.3 8.7 0 10 0c1.4 0 2.7.3 3.9.8 1.2.5 2.3 1.2 3.2 2.1.9.9 1.6 2 2.1 3.2.5 1.2.8 2.6.8 3.9 0 1.3-.3 2.7-.8 3.9-.5 1.2-1.2 2.3-2.1 3.2-.9.9-2 1.6-3.2 2.1-1.2.5-2.6.8-3.9.8zm0-2c2.2 0 4.1-.8 5.7-2.3 1.6-1.5 2.3-3.4 2.3-5.7 0-.9-.1-1.8-.4-2.6-.3-.8-.7-1.6-1.3-2.3L5.1 16.3c.7.5 1.5 1 2.3 1.3s1.7.4 2.6.4zm-6.3-3.1L14.9 3.7c-.7-.5-1.5-1-2.3-1.3-.8-.3-1.7-.4-2.6-.4-2.2 0-4.1.8-5.7 2.3S2 7.8 2 10c0 .9.1 1.8.4 2.6.3.8.8 1.6 1.3 2.3z'/></svg>")
      )};
  }
}
