.page-header {
  backdrop-filter: blur(#{itl-rem-calc(10)});
  background: rgba($header-bg, 0.8);
  box-shadow: #{itl-rem-calc(0 4 4 0)} rgba($black, 0.05);
  // height: $header-height-mobile;
  left: calc(50% - 50vw);
  position: relative;
  top: 0;
  width: 100vw;
  z-index: 999;

  @include media-breakpoint-up(md) {
    align-items: stretch;
    // height: $header-height-dt;
    // position: fixed;
  }

  .page-header__container {
    margin: 0 auto;
    max-width: #{itl-rem-calc(2000)};
    padding: #{itl-rem-calc(0 20)};

    @media screen and (width >= 1520px) {
      padding: #{itl-rem-calc(0 80)};
    }
  }

  .logo {
    display: table;
    width: #{itl-rem-calc(83)};

    @include media-breakpoint-up(md) {
      width: #{itl-rem-calc(100)};
    }

    @include media-breakpoint-up(xl) {
      width: #{itl-rem-calc(170)};
    }
  }

  .short-links {
    display: flex;
    gap: #{itl-rem-calc(0 13)};
    justify-content: flex-end;

    @include media-breakpoint-up(md) {
      gap: #{itl-rem-calc(0 20)};
    }
  }

  .short-link__item {
    @include set-transition(color);

    align-items: center;
    color: map-get($greys, 100);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: relative;
    text-decoration: none;

    &:hover {
      color: $primary;
    }
  }

  .short-link__counter {
    align-items: center;
    background: $primary;
    border-radius: 100%;
    color: $white;
    display: flex;
    font-size: #{itl-rem-calc(10)};
    height: #{itl-rem-calc(16)};
    justify-content: center;
    line-height: 1;
    position: absolute;
    right: #{itl-rem-calc(-8)};
    top: #{itl-rem-calc(-8)};
    width: #{itl-rem-calc(16)};

    @include media-breakpoint-up(md) {
      font-size: #{itl-rem-calc(12)};
      height: #{itl-rem-calc(20)};
      right: #{itl-rem-calc(-12)};
      top: #{itl-rem-calc(-12)};
      width: #{itl-rem-calc(20)};
    }
  }

  .short-link__icon {
    display: block;
    height: #{itl-rem-calc(20)};
    position: relative;

    .icon {
      display: block;
      height: 100%;
      width: auto;
    }
  }

  .short-link__text {
    display: none;

    @include media-breakpoint-up(md) {
      @include dt-c-c5;

      color: map-get($greys, 100);
      display: block;
      padding: #{itl-rem-calc(10 0 0)};
    }
  }
}
