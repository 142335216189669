/* noto-sans-300 - latin */
@font-face {
  font-display: swap;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/noto-sans-v32-latin-300.woff2') format('woff2');
}

/* noto-sans-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/noto-sans-v32-latin-regular.woff2') format('woff2');
}

/* noto-sans-500 - latin */
@font-face {
  font-display: swap;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/noto-sans-v32-latin-500.woff2') format('woff2');
}

/* noto-sans-700 - latin */
@font-face {
  font-display: swap;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/noto-sans-v32-latin-700.woff2') format('woff2');
}

/* noto-sans-800 - latin */
@font-face {
  font-display: swap;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../../fonts/noto-sans-v32-latin-800.woff2') format('woff2');
}
