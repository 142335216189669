.category-item {
  @include set-transition((border, color));

  background: $white;
  border: #{itl-rem-calc(1)} solid map-get($greys, 20);
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: #{itl-rem-calc(12 12 8)};
  position: relative;
  width: 100%;

  &:hover {
    border-color: $primary;
    box-shadow: #{itl-rem-calc(0 0 8 0)} rgba(map-get($greys, 60), 0.12),
      #{itl-rem-calc(0 0 6 0)} rgba(map-get($greys, 100), 0.12);
    color: $primary;
  }
}

.category-item--large {
  @include media-breakpoint-up(xl) {
    padding: #{itl-rem-calc(22 22 18)};
  }
}

.category-item__image {
  display: block;
  position: relative;
  width: 100%;

  &::before {
    content: '';
    display: block;
    padding-bottom: 60%;
  }

  img {
    display: block;
    height: 100%;
    left: 0;
    object-fit: contain;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.category-item__link {
  @include mob-c-c3;

  @include media-breakpoint-up(xl) {
    @include dt-c-c3;
  }

  color: inherit;
  display: block;
  font-weight: 500;
  hyphens: auto;
  overflow: hidden;
  padding: #{itl-rem-calc(17 0 0)};
  text-align: center;
  text-decoration: none;

  &::before {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
