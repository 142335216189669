.product-card {
  @include set-transition(border-color);

  background: $white;
  border: #{itl-rem-calc(1)} solid map-get($greys, 20);
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;

  &:hover {
    border-color: $primary;
  }

  &.product-card--full-img {
    border: 0;
  }
}

.product-card__image {
  img {
    height: auto;
    width: 100%;
  }

  .product-card--full-img & {
    .product-card-lg-cover & {
      @include media-breakpoint-up(lg) {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.product-card__text {
  margin-top: auto;
  padding: #{itl-rem-calc(20)};

  .product-card--full-img & {
    backdrop-filter: blur(#{itl-rem-calc(4)});
    background: linear-gradient(180deg, rgba($white, 0) 0%, $white 100%);
    bottom: 0;
    left: 0;
    padding: #{itl-rem-calc(44 20 14 20)};
    position: absolute;
    width: 100%;

    @include media-breakpoint-up(xl) {
      padding: #{itl-rem-calc(60 40 20 40)};
    }
  }
}

.product-card__link {
  display: block;
  inset: 0;
  position: absolute;
}

.product-card__labels {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  gap: #{itl-rem-calc(8)};
  justify-content: flex-end;
  left: #{itl-rem-calc(12)};
  position: absolute;
  right: #{itl-rem-calc(12)};
  top: #{itl-rem-calc(12)};

  .product-card--small & {
    font-size: #{itl-rem-calc(11)};
  }

  @include media-breakpoint-up(xl) {
    left: #{itl-rem-calc(20)};
    right: #{itl-rem-calc(20)};
    top: #{itl-rem-calc(20)};
  }
}

.product-card-simple {
  position: relative;
  width: 100%;
}

.product-card-simple__img {
  @include set-transition(border-color);

  background: $white;
  border: #{itl-rem-calc(1)} solid map-get($greys, 20);
  border-radius: $border-radius;
  overflow: hidden;

  .product-card-simple:hover & {
    border-color: $body-color;
  }
}

.product-card-simple__link {
  color: $body-color;
  display: block;
  font-size: #{itl-rem-calc(12)};
  hyphens: auto;
  line-height: 1.4;
  padding: #{itl-rem-calc(9 0 0)};
  text-decoration: none;

  &::before {
    content: '';
    inset: 0;
    position: absolute;
  }
}
