.expert-approved__spacer {
  background: $body-color;
  border-radius: 100%;
  display: table;
  height: #{itl-rem-calc(6)};
  margin: #{itl-rem-calc(0 11)};
  width: #{itl-rem-calc(6)};
}

.swiper-pagination {
  .product-slider__images & {
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
}

.product-info {
  @include media-breakpoint-down(md) {
    border: #{itl-rem-calc(1)} solid map-get($greys, 30);
    border-radius: $border-radius;
    padding: #{itl-rem-calc(26 10)};
  }
}

.product-detail__payments {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: #{itl-rem-calc(10)};
  list-style: none;
  margin: 0;
  padding: 0;
}

.product-detail__payments-item {
  display: flex;
  height: #{itl-rem-calc(16)};

  .icon {
    height: #{itl-rem-calc(16)};
    width: auto;
  }
}

.tab-pane__read-more {
  position: relative;

  &.active {
    &::before {
      background: linear-gradient(to bottom, rgba(map-get($greys, 10),0) 0%, rgba(map-get($greys, 10),1) 80%);
      bottom: 0;
      content: "";
      height: 100px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
}

.toast__image {
  margin-right: #{itl-rem-calc(15)};
  min-width: 3rem;
  position: relative;

  @include media-breakpoint-up(xl) {
    margin-right: #{itl-rem-calc(30)};
  }
}

/* stylelint-disable selector-class-pattern */

.toast__image__checkmark {
  align-items: center;
  background-color: map-get($greens, 10);
  border-radius: 100%;
  color: $white;
  display: flex;
  height: 1.5rem;
  justify-content: center;
  position: absolute;
  right: -.5rem;
  top: 0;
  width: 1.5rem;
}

/* stylelint-enable */

.toast__copy {
  font-size: #{itl-rem-calc(14)};
  font-weight: 700;
  line-height: 1.2;
  margin: 0;

  @include media-breakpoint-up(lg) {
    font-size: #{itl-rem-calc(16)};
  }
}

.toast__cta {
  margin-left: #{itl-rem-calc(15)};
  margin-right: #{itl-rem-calc(10)};

  @include media-breakpoint-up(lg) {
    margin-right: #{itl-rem-calc(20)};
  }

  @include media-breakpoint-up(xl) {
    margin-left: #{itl-rem-calc(30)};
    margin-right: #{itl-rem-calc(30)};
  }

  .btn-link {
    font-size: #{itl-rem-calc(14)};

    @include media-breakpoint-up(lg) {
      font-size: #{itl-rem-calc(16)};
    }

    @include media-breakpoint-up(xl) {
      font-size: #{itl-rem-calc(18)};
      text-wrap: nowrap;
    }
  }
}

.toast__close {
  align-items: center;
  background-color: map-get($greys, 10);
  border: 1px solid map-get($greys, 40);
  border-radius: 100%;
  color: map-get($greys, 60);
  display: flex;
  height: 1.5rem;
  justify-content: center;
  padding: 0;
  position: absolute;
  right: -.5rem;
  top: -.125rem;
  width: 1.5rem;
}
