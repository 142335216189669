@mixin dt-h-h1 {
  font-size: #{itl-rem-calc(38)};
  font-weight: 800;
  line-height: 1.2;
}

.dt-h-h1 {
  @include media-breakpoint-up(xl) {
    @include dt-h-h1;
  }
}

@mixin mob-h-h1 {
  font-size: #{itl-rem-calc(26)};
  font-weight: 800;
  line-height: 1.2;
}

.mob-h-h1 {
  @include media-breakpoint-down(xl) {
    @include mob-h-h1;
  }
}

@mixin dt-h-h2 {
  font-size: #{itl-rem-calc(30)};
  font-weight: 700;
  line-height: 1.2;
}

.dt-h-h2 {
  @include media-breakpoint-up(xl) {
    @include dt-h-h2;
  }
}

@mixin mob-h-h2 {
  font-size: #{itl-rem-calc(24)};
  font-weight: 700;
  line-height: 1.2;
}

.mob-h-h2 {
  @include media-breakpoint-down(xl) {
    @include mob-h-h2;
  }
}

@mixin dt-h-h3 {
  font-size: #{itl-rem-calc(28)};
  font-weight: 400;
  line-height: 1.2;
}

.dt-h-h3 {
  @include media-breakpoint-up(xl) {
    @include dt-h-h3;
  }
}

@mixin mob-h-h3 {
  font-size: #{itl-rem-calc(23)};
  font-weight: 400;
  line-height: 1.2;
}

.mob-h-h3 {
  @include media-breakpoint-down(xl) {
    @include mob-h-h3;
  }
}

@mixin dt-h-h4 {
  font-size: #{itl-rem-calc(24)};
  font-weight: 700;
  line-height: 1.2;
}

.dt-h-h4 {
  @include media-breakpoint-up(xl) {
    @include dt-h-h4;
  }
}

@mixin mob-h-h4 {
  font-size: #{itl-rem-calc(22)};
  font-weight: 700;
  line-height: 1.2;
}

.mob-h-h4 {
  @include media-breakpoint-down(xl) {
    @include mob-h-h4;
  }
}

@mixin dt-h-h5 {
  font-size: #{itl-rem-calc(21)};
  font-weight: 700;
  line-height: 1.2;
}

.dt-h-h5 {
  @include media-breakpoint-up(xl) {
    @include dt-h-h5;
  }
}

@mixin mob-h-h5 {
  font-size: #{itl-rem-calc(18)};
  font-weight: 700;
  line-height: 1.2;
}

.mob-h-h5 {
  @include media-breakpoint-down(xl) {
    @include mob-h-h5;
  }
}

@mixin mob-h-h6 {
  font-size: #{itl-rem-calc(16)};
  font-weight: 400;
  line-height: 1.2;
}

.mob-h-h6 {
  @include media-breakpoint-down(xl) {
    @include mob-h-h6;
  }
}

@mixin dt-h-h6 {
  font-size: #{itl-rem-calc(18)};
  font-weight: 400;
  line-height: 1.2;
}

.dt-h-h6 {
  @include media-breakpoint-up(xl) {
    @include dt-h-h6;
  }
}

@mixin dt-heading-h4 {
  font-size: #{itl-rem-calc(18)};
  font-weight: 700;
  line-height: 1.4;
}

.dt-heading-h4 {
  @include media-breakpoint-up(xl) {
    @include dt-heading-h4;
  }
}

@mixin dt-c-c1 {
  font-size: #{itl-rem-calc(18)};
  font-weight: 700;
  line-height: 1;
}

.dt-c-c1 {
  @include media-breakpoint-up(xl) {
    @include dt-c-c1;
  }
}

@mixin mob-c-c1 {
  font-size: #{itl-rem-calc(16)};
  font-weight: 700;
  line-height: 1;
}

.mob-c-c1 {
  @include media-breakpoint-down(xl) {
    @include mob-c-c1;
  }
}

@mixin dt-c-c2 {
  font-size: #{itl-rem-calc(16)};
  font-weight: 700;
  line-height: 1.1;
}

.dt-c-c2 {
  @include media-breakpoint-up(xl) {
    @include dt-c-c2;
  }
}

@mixin mob-c-c2 {
  font-size: #{itl-rem-calc(14)};
  font-weight: 700;
  line-height: 1.1;
}

.mob-c-c2 {
  @include media-breakpoint-down(xl) {
    @include mob-c-c2;
  }
}

@mixin dt-c-c3 {
  font-size: #{itl-rem-calc(16)};
  font-weight: 500;
  line-height: 1.2;
}

.dt-c-c3 {
  @include media-breakpoint-up(xl) {
    @include dt-c-c3;
  }
}

@mixin mob-c-c3 {
  font-size: #{itl-rem-calc(14)};
  font-weight: 500;
  line-height: 1.2;
}

.mob-c-c3 {
  @include media-breakpoint-down(xl) {
    @include mob-c-c3;
  }
}

@mixin dt-c-c4 {
  font-size: #{itl-rem-calc(14)};
  font-weight: 700;
  line-height: 1;
}

.dt-c-c4 {
  @include media-breakpoint-up(xl) {
    @include dt-c-c4;
  }
}

@mixin mob-c-c4 {
  font-size: #{itl-rem-calc(12)};
  font-weight: 700;
  line-height: 1;
}

.mob-c-c4 {
  @include media-breakpoint-down(xl) {
    @include mob-c-c4;
  }
}

@mixin dt-c-c5 {
  font-size: #{itl-rem-calc(12)};
  font-weight: 400;
  line-height: 1;
}

.dt-c-c5 {
  @include dt-c-c5;
}

@mixin mob-c-c5 {
  font-size: #{itl-rem-calc(12)};
  font-weight: 400;
  line-height: 1;
}

.mob-c-c5 {
  @include media-breakpoint-down(xl) {
    @include mob-c-c5;
  }
}

@mixin dt-f-f1 {
  font-size: #{itl-rem-calc(16)};
  font-weight: 700;
  line-height: 1.2;
}

.dt-f-f1 {
  @include media-breakpoint-up(xl) {
    @include dt-f-f1;
  }
}

@mixin mob-f-f1 {
  font-size: #{itl-rem-calc(14)};
  font-weight: 700;
  line-height: 1.2;
}

.mob-f-f1 {
  @include media-breakpoint-down(xl) {
    @include mob-f-f1;
  }
}

@mixin dt-f-f2 {
  font-size: #{itl-rem-calc(16)};
  font-weight: 300;
  line-height: 1.2;
}

.dt-f-f2 {
  @include media-breakpoint-up(xl) {
    @include dt-f-f2;
  }
}

@mixin mob-f-f2 {
  font-size: #{itl-rem-calc(14)};
  font-weight: 300;
  line-height: 1.2;
}

.mob-f-f2 {
  @include media-breakpoint-down(xl) {
    @include mob-f-f2;
  }
}

@mixin dt-f-f3 {
  font-size: #{itl-rem-calc(12)};
  font-weight: 700;
  line-height: 1.2;
}

.dt-f-f3 {
  @include media-breakpoint-up(xl) {
    @include dt-f-f3;
  }
}

@mixin mob-f-f3 {
  font-size: #{itl-rem-calc(12)};
  font-weight: 700;
  line-height: 1.2;
}

.mob-f-f3 {
  @include media-breakpoint-down(xl) {
    @include mob-f-f3;
  }
}

@mixin dt-f-f4 {
  font-size: #{itl-rem-calc(12)};
  font-weight: 400;
  line-height: 1.2;
}

.dt-f-f4 {
  @include media-breakpoint-up(xl) {
    @include dt-f-f4;
  }
}

@mixin mob-f-f4 {
  font-size: #{itl-rem-calc(12)};
  font-weight: 400;
  line-height: 1.2;
}

.mob-f-f4 {
  @include media-breakpoint-down(xl) {
    @include mob-f-f4;
  }
}

@mixin dt-p-p1 {
  font-size: #{itl-rem-calc(22)};
  font-weight: 400;
  line-height: 1.5;
}

.dt-p-p1 {
  @include dt-p-p1;
}

@mixin mob-p-p1 {
  font-size: #{itl-rem-calc(18)};
  font-weight: 400;
  line-height: 1.5;
}

.mob-p-p1 {
  @include mob-p-p1;
}

@mixin dt-p-p2 {
  font-size: #{itl-rem-calc(18)};
  font-weight: 400;
  line-height: 1.5;
}

.dt-p-p2 {
  @include media-breakpoint-up(xl) {
    @include dt-p-p2;
  }
}

@mixin dt-p-p2-bold {
  font-size: #{itl-rem-calc(18)};
  font-weight: 700;
  line-height: 1.5;
}

.dt-p-p2-bold {
  @include media-breakpoint-up(xl) {
    @include dt-p-p2-bold;
  }
}

@mixin mob-p-p2 {
  font-size: #{itl-rem-calc(16)};
  font-weight: 400;
  line-height: 1.5;
}

.mob-p-p2 {
  @include media-breakpoint-down(xl) {
    @include mob-p-p2;
  }
}

@mixin dt-p-p3 {
  font-size: #{itl-rem-calc(16)};
  font-weight: 400;
  line-height: 1.5;
}

.dt-p-p3 {
  @include media-breakpoint-up(xl) {
    @include dt-p-p3;
  }
}

@mixin mob-p-p3 {
  font-size: #{itl-rem-calc(16)};
  font-weight: 400;
  line-height: 1.5;
}

.mob-p-p3 {
  @include media-breakpoint-down(xl) {
    @include mob-p-p3;
  }
}

@mixin dt-p-p4 {
  font-size: #{itl-rem-calc(14)};
  font-weight: 400;
  line-height: 1.2;
}

.dt-p-p4 {
  @include media-breakpoint-up(xl) {
    @include dt-p-p4;
  }
}

@mixin mob-p-p4 {
  font-size: #{itl-rem-calc(14)};
  font-weight: 400;
  line-height: 1.2;
}

.mob-p-p4 {
  @include media-breakpoint-down(xl) {
    @include mob-p-p4;
  }
}

@mixin mob-p-15 {
  font-size: #{itl-rem-calc(15)};
  font-weight: 400;
  line-height: 1.2;
}

.mob-p-15 {
  @include media-breakpoint-down(xl) {
    @include mob-p-15;
  }
}

h1,
.h1 {
  @include mob-h-h1;

  @include media-breakpoint-up(xl) {
    @include dt-h-h1;
  }
}

h2,
.h2 {
  @include mob-h-h2;

  margin-bottom: #{itl-rem-calc(10)};

  @include media-breakpoint-up(xl) {
    @include dt-h-h2;
  }
}

h5,
.h5 {
  @include mob-h-h5;

  @include media-breakpoint-up(xl) {
    @include dt-h-h5;
  }
}

@mixin font-inherit {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

.font-inherit {
  @include font-inherit;
}

.link-reset {
  @include set-transition(color);

  color: inherit;
  cursor: pointer;
  text-decoration: none;

  &:hover,
  &.active {
    color: $primary;
  }
}

.link-primary {
  color: $primary;
  cursor: pointer;
  text-decoration: none;

  &:hover,
  &.active {
    text-decoration: underline;
  }
}

// links
.icon-link {
  @include set-transition(color);

  background: none;
  border: 0;
  color: $body-color;
  cursor: pointer;
  margin: 0;
  padding: 0;
  text-decoration: none;

  &::after {
    display: none;
  }

  @media (hover: hover) {
    &:not(.icon-link--hover-unset) {
      &:hover {
        color: $primary;
      }
    }
  }

  .icon {
    flex-shrink: 0;
    height: 1em;
    width: auto;
  }

  .icon-link__icon-rounded {
    align-items: center;
    background: map-get($greys, 10);
    border: #{itl-rem-calc(1)} solid map-get($greys, 20);
    border-radius: 100%;
    display: flex;
    flex-shrink: 0;
    height: 1.5em;
    justify-content: center;
    padding: 0.25em;
    width: 1.5em;

    .icon {
      height: auto;
      max-height: 100%;
      max-width: 100%;
      width: 100%;
    }
  }
}

.last-child-no-mb {
  > *:last-child {
    margin-bottom: 0;
  }
}

.text-message-success {
  color: $message-success !important;
}

.text-message-warning {
  color: $message-warning !important;
}

.text-message-error {
  color: $message-error !important;
}
