.nav-tabs {
  background: $white;
  border: #{itl-rem-calc(1)} solid map-get($greys, 20);
  border-bottom: 0;
  border-radius: $border-radius $border-radius 0 0;
  display: flex;
  gap: #{itl-rem-calc(0 20)};
  justify-content: center;
  margin-bottom: #{itl-rem-calc(-1)};
  overflow-x: auto;
  padding: #{itl-rem-calc(10)};

  .nav-link {
    @include set-transition((background, color, border-color));

    border: #{itl-rem-calc(1)} solid rgba(map-get($greys, 20), 0);
    border-radius: $border-radius;
    color: map-get($greys, 60);
    cursor: pointer;
    display: block;
    font-size: #{itl-rem-calc(16)};
    font-weight: 500;
    line-height: 1;
    padding: #{itl-rem-calc(6 12)};
    text-decoration: none;
    white-space: nowrap;

    &.active {
      background: map-get($greys, 100);
      border-color: map-get($greys, 20);
      color: $white;
    }

    &:hover:not(.active) {
      border-color: rgba(map-get($greys, 20), 0);
      color: $primary;
    }
  }
}
