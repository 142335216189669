.filter-list {
  @include media-breakpoint-down(lg) {
    @include set-transition(transform);

    background: $grey-background;
    height: 100vh;
    padding: #{itl-rem-calc(20)};
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
    z-index: 999;

    &:not(.active) {
      transform: translateX(100%);
    }

    form {
      max-height: 100%;
      overflow: hidden;
      overflow-y: auto;
      padding: #{itl-rem-calc(0 0 50 0)};
    }
  }

  @include media-breakpoint-up(lg) {
    background: $white;
    border-radius: $border-radius;
    padding: #{itl-rem-calc(20 20 20 0)};
  }
}

.filter-list__toggle {
  border-bottom: #{itl-rem-calc(1)} solid map-get($greys, 20);
  display: flex;
  font-size: #{itl-rem-calc(18)};
  font-weight: 700;
  justify-content: space-between;
  line-height: 1;
  padding: #{itl-rem-calc(0 0 20 0)};
  position: relative;
  width: 100%;

  &::before,
  &::after {
    background: $body-color;
    content: '';
    display: block;
    position: absolute;
    transform: rotate(45deg);
  }

  &::before {
    height: #{itl-rem-calc(16)};
    right: #{itl-rem-calc(7)};
    top: #{itl-rem-calc(2)};
    width: #{itl-rem-calc(2)};
  }

  &::after {
    height: #{itl-rem-calc(2)};
    right: 0;
    top: #{itl-rem-calc(9)};
    width: #{itl-rem-calc(16)};
  }
}

.disclosure-filter-btn {
  @include dt-p-p2-bold;
  @include set-transition(color);

  background: none;
  border: none;
  color: $body-color;
  display: flex;
  hyphens: auto;
  justify-content: space-between;
  margin: 0;
  position: relative;
  text-align: left;
  width: 100%;

  @include media-breakpoint-down(lg) {
    border-bottom: #{itl-rem-calc(1)} solid map-get($greys, 20);
    padding: #{itl-rem-calc(20 0)};
  }

  @include media-breakpoint-up(lg) {
    padding: #{itl-rem-calc(0 30 16 0)};
  }

  @include media-breakpoint-up(xxl) {
    padding: #{itl-rem-calc(0 30 22 0)};
  }

  &:hover {
    color: $primary;
  }

  &[aria-expanded='true'] {
    @include media-breakpoint-down(lg) {
      border: none;
    }
  }

  .icon {
    display: block;
    position: absolute;
    right: 0;
    top: #{itl-rem-calc(22)};
    width: #{itl-rem-calc(14)};

    @include media-breakpoint-up(lg) {
      top: #{itl-rem-calc(1)};
    }
  }

  &.disclosure-filter-btn--sorting {
    @include media-breakpoint-up(lg) {
      align-items: center;
      display: flex;
      padding: 0;
      @include dt-p-p3;

      .icon {
        display: block;
        height: #{itl-rem-calc(7)};
        margin: 0;
        top: #{itl-rem-calc(6)};
        width: auto;
      }
    }
  }
}

.disclosure-filter-panel {
  margin: #{itl-rem-calc(0 2)};
  overflow: hidden;
  position: relative;

  @include media-breakpoint-up(lg) {
    margin: #{itl-rem-calc(0 2 22)};
  }
}

.disclosure-filter-panel--sorting {
  @include media-breakpoint-up(lg) {
    background: $white;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    padding: #{itl-rem-calc(20)};
    position: absolute;
    right: 0;
    top: calc(100% + #{itl-rem-calc(10)});
    white-space: nowrap;
    z-index: 999;
  }
}

.disclosure-filter-panel__inner {
  max-height: #{itl-rem-calc(150)};
  overflow: hidden;
  overflow-y: auto;

  @include media-breakpoint-down(lg) {
    border-bottom: #{itl-rem-calc(1)} solid map-get($greys, 20);
    padding: #{itl-rem-calc(0 0 22)};
  }

  .disclosure-filter-panel--price & {
    padding: #{itl-rem-calc(0 0 30)};

    @include media-breakpoint-up(lg) {
      padding: #{itl-rem-calc(0 0 8 0)};
    }
  }
}

.filter-item {
  margin-bottom: #{itl-rem-calc(10)};

  &:last-child {
    margin-bottom: 0;
  }
}

// Price range filter
.price-range-filter {
  align-items: center;
  display: flex;
  font-size: #{itl-rem-calc(16)};
  font-weight: 500;
  justify-content: space-between;
  line-height: 1;
  padding: #{itl-rem-calc(0 0 20 0)};
}

.price-range-filter__spacer {
  background: map-get($greys, 30);
  flex-shrink: 1;
  height: #{itl-rem-calc(1)};
  margin: #{itl-rem-calc(0 20)};
  width: #{itl-rem-calc(24)};
}
