.links-banner {
  padding: #{itl-rem-calc(20 0)};

  @include media-breakpoint-down(xl) {
    position: relative;
    z-index: 0;

    &::before {
      background: $secondary;
      content: '';
      display: block;
      height: 100%;
      left: 50%;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      width: 100vw;
      z-index: -1;
    }
  }

  @include media-breakpoint-up(xl) {
    background: $secondary;
    border-radius: $border-radius;
    padding: #{itl-rem-calc(40 0)};
  }
}

.links-banner__title {
  margin-bottom: #{itl-rem-calc(21)};
}

.links-banner__text {
  @include mob-p-p2;

  @include media-breakpoint-up(xl) {
    @include dt-p-p2;
  }
}

.links-banner__links {
  display: flex;
  flex-wrap: wrap;
  gap: #{itl-rem-calc(10)};
  padding: #{itl-rem-calc(3 0 0 0)};

  @include media-breakpoint-up(xl) {
    gap: #{itl-rem-calc(30)};
    padding: #{itl-rem-calc(23 0 0 0)};
  }
}
