// Apply transition settings for multiple properties from a list
//
// @param {list} $props - list of CSS properties
// @param {string} $timing - transition timing
// @param {string} $easing - transition easing
//
// @usage
// .button {
//   @include set-transition((color, border-color, background-color), 300ms, ease-in);
// }
//
// @output
// .button {
//   transition: color 300ms ease-in,
//     border-color 300ms ease-in,
//     background-color 300ms ease-in;
// }

@mixin set-transition($props, $timing: 0.15s, $easing: ease-in-out) {
  $val: '';

  @each $prop in $props {
    $index: index($props, $prop);
    $prefix: ', ';

    @if $index == 1 {
      $prefix: '';
    }

    $val: #{$val + $prefix + $prop + ' ' $timing + ' ' + $easing};
  }

  transition: $val;
}
