/* stylelint-disable selector-class-pattern */
.yarl__slide {
  padding: #{itl-rem-calc(82 20 20)};
}

.yarl__navigation_prev,
.yarl__navigation_next {
  top: calc(50% + (#{itl-rem-calc(82)} / 2) - (#{itl-rem-calc(20)} / 2));
}

.yarl__button,
.yarl__thumbnails_thumbnail_icon {
  filter: none;
}

.yarl__thumbnails_thumbnail {
  border: #{itl-rem-calc(1)} solid $yarl-thumbnails-thumbnail-border;

  @media (hover: hover) {
    &:hover {
      border-color: $yarl-thumbnails-thumbnail-active-border-color;
    }
  }
}

.yarl__thumbnails_thumbnail_active {
  border-color: $yarl-thumbnails-thumbnail-active-border-color;
}
