.dt-main-navigation__ul {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: #{itl-rem-calc(30)};
  margin: 0;
  overflow: hidden;
  padding: 0;

  @include media-breakpoint-up(md) {
    padding: #{itl-rem-calc(0 40 0 0)};
  }

  @include media-breakpoint-up(xxl) {
    padding: #{itl-rem-calc(0 70 0 0)};
  }
}

.dt-main-navigation__li {
  align-items: center;
  display: flex;
  list-style: none;
  padding: #{itl-rem-calc(0 20 0 0)};

  @include media-breakpoint-up(xl) {
    padding: #{itl-rem-calc(0 30 0 0)};
  }

  @include media-breakpoint-up(xxl) {
    padding: #{itl-rem-calc(0 40 0 0)};
  }

  @media screen and (width >= 1600px) {
    padding: #{itl-rem-calc(0 50 0 0)};
  }

  &:last-child {
    padding-right: 0;
  }
}

.dt-main-navigation__a {
  @include set-transition(color);

  align-items: center;
  color: $body-color;
  display: flex;
  font-size: #{itl-rem-calc(12)};
  font-weight: 700;
  height: #{itl-rem-calc(30)};
  line-height: 1;
  text-decoration: none;
  white-space: nowrap;

  @include media-breakpoint-up(xl) {
    font-size: #{itl-rem-calc(14)};
  }

  @include media-breakpoint-up(xxl) {
    font-size: #{itl-rem-calc(16)};
  }

  &:hover,
  &.active {
    color: $primary;
  }
}

.dt-main-navigation__a--more {
  .icon {
    padding: 0 0.8em 0 0;
  }
}
