.hero {
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  width: 100vw;
}

.hero__content {
  align-items: center;
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
